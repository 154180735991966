import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  StyledFilters,
  StyledPaidCommissionsTable,
  StyledTable,
} from './styled'
import { ReactBaseElement } from '../../types/react'
import { getRoleResourceColumns, getTableData } from '../../utils/tables'
import Table from '../../components/organisms/Table'
import { useUser } from '../../providers/User'
import { useMutation } from 'react-query'
import Spinner from '../../components/atoms/Spinner'
import Container from '../../components/atoms/Container'
import { StyledDescription } from '../../components/styled/Description'
import Input from '../../components/atoms/Input'
import { Icons, Role } from '../../types/enums'
import { CommissionResponse, CommissionTableRow } from '../../types/commissions'
import { getCommissions } from '../../services/commissions'
import {
  getConditionalStyles,
  parseCommissionForTable,
} from '../../utils/commissions'
import { Mappeable } from '../../types/objects'
import PaymentsListCell from '../../components/organisms/Table/Cells/PaymentsList'
import { useTranslation } from '../../providers/I18n'
import Select from '../../components/atoms/Select'
import { SelectOption } from '../../types/html'
import Button from '../../components/molecules/Button'
import DateInput from '../../components/atoms/DateInput'
import { TableSelectedData } from '../../types/tables'
import { StyledTotalForCommission } from './styled'
import { formatCurrency } from '../../utils/currency'

type PaidCommissionsTableProps = ReactBaseElement

const PaidCommissionsTable: React.FC<PaidCommissionsTableProps> = () => {
  const { user } = useUser()
  const { getTranslation } = useTranslation()
  const [search, setSearch] = useState('')
  const [selectedCommissions, setSelectedCommissions] =
    useState<TableSelectedData>()
  const [localFilters, setLocalFilters] = useState<Mappeable<SelectOption>>({})
  const fromDateRef = useRef<HTMLInputElement>(null)
  const toDateRef = useRef<HTMLInputElement>(null)
  const [stateCommissions, setStateCommissions] = useState<
    CommissionResponse[] | undefined
  >(undefined)
  const navigator = useNavigate()

  const {
    data: commissions,
    isLoading: isLoadingCommissions,
    mutate: fetchCommissions,
  } = useMutation(getCommissions, {
    onSuccess: (data) => {
      setStateCommissions(data)
    },
  })

  useEffect(() => {
    if (commissions) {
      const filteredCommissions = commissions!.filter(
        (comission: CommissionResponse) => {
          const celulaFilter = localFilters.celula?.value
          if (!celulaFilter || celulaFilter === '--') {
            return true
          }
          if (comission.celula) {
            return comission.celula._id.toString() === celulaFilter
          } else if (comission.owner) {
            return comission.owner._id.toString() === celulaFilter
          }
        }
      )

      const searchCommissions = filteredCommissions!.filter(
        (commission: CommissionResponse) => {
          return (
            commission.credit.code.toString().includes(search) ||
            commission.credit.client.name.toLowerCase().includes(search) ||
            commission.celula.name.toLowerCase().includes(search) ||
            commission.credit.bank.name.toLowerCase().includes(search) ||
            commission.credit.client.meta.contactForm.name
              .toLowerCase()
              .includes(search) ||
            commission.owner.displayName.toLowerCase().includes(search)
          )
        }
      )

      setStateCommissions(searchCommissions)
    }
  }, [localFilters, search])

  const parsedCredits = useMemo(() => {
    if (stateCommissions && stateCommissions.length) {
      return parseCommissionForTable(stateCommissions, { getTranslation }, true)
    }
    return []
  }, [stateCommissions])

  const filters = useMemo(() => {
    const celulas: Mappeable<string> = {}
    commissions?.forEach((commission: CommissionResponse) => {
      if (commission.celula) {
        celulas[commission.celula._id.toString()] = commission.celula.name
      } else if (commission.owner) {
        celulas[commission.owner._id.toString()] = commission.owner.displayName
      }
    })

    const celulasList = Object.keys(celulas).map((celulaKey) => ({
      value: celulaKey,
      label: celulas[celulaKey],
    }))

    const sortedCelulas = celulasList.sort((a, b) => {
      return a.label > b.label ? 0 : -1
    })

    return {
      celulas: sortedCelulas,
    }
  }, [commissions])

  const totalSelected = useMemo(() => {
    let sum = 0
    if (selectedCommissions && selectedCommissions?.selectedCount > 0) {
      sum = selectedCommissions?.selectedRows.reduce((total, current) => {
        const realCommission = commissions?.find(
          (c: CommissionResponse) => c._id === current.id
        )
        if (realCommission) {
          return (
            total + (realCommission.granted ? realCommission.granted.amount : 0)
          )
        }
        return total
      }, 0)
    }
    return sum
  }, [selectedCommissions])

  const CommissionsColumns = getRoleResourceColumns('commissions', user?.role)
  const CommissionsData = getTableData(CommissionsColumns, parsedCredits)

  const paidCommissions: Mappeable<string>[] = []
  CommissionsData.forEach((commission) => {
    if (!commission.privateNeedsPayment) {
      paidCommissions.push(commission)
    }
  })

  const handleFetchCommissions = () => {
    const fromDate = fromDateRef.current?.value
    const toDate = toDateRef.current?.value
    fetchCommissions({
      from: fromDate,
      to: toDate,
      needsPayment: false,
    })
  }

  const handleClick = (commission: CommissionTableRow) => {
    navigator(`/credito/${commission.creditCode}`)
  }

  const handleSelect = (selected: TableSelectedData) => {
    setSelectedCommissions(selected)
  }

  const handleLocalFilter = (filter: string) => (option: SelectOption) => {
    setLocalFilters({
      ...localFilters,
      [filter]: option,
    })
  }

  const handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const searchValue = event.currentTarget.value.toLowerCase()
    setSearch(searchValue)
  }

  const conditional = getConditionalStyles()

  const showCommissionsActions = [Role.Admin, Role.Director].includes(
    user?.role as Role
  )

  return (
    <StyledPaidCommissionsTable>
      <StyledFilters>
        <StyledDescription>Selecciona Fechas:</StyledDescription>
        <Container direction="row">
          <DateInput label="Desde" ref={fromDateRef} />
          <DateInput label="Hasta" ref={toDateRef} />
          <Button onClick={handleFetchCommissions}>Buscar</Button>
        </Container>
      </StyledFilters>
      {commissions && commissions.length > 0 && (
        <StyledTable>
          <Container direction="row">
            {showCommissionsActions && (
              <Select
                label="Celula"
                items={filters.celulas}
                onChange={handleLocalFilter('celula')}
                value={localFilters.celula}
              />
            )}
            <Input
              placeholder="Buscar"
              onChange={handleSearch}
              preIcon={Icons.SEARCH}
              value={search}
            ></Input>
          </Container>
          <StyledTotalForCommission>
            Total seleccionado: {formatCurrency(totalSelected)}
          </StyledTotalForCommission>
          <Table
            columns={CommissionsColumns}
            data={paidCommissions}
            onClick={handleClick}
            onSelect={handleSelect}
            conditionalRowStyles={conditional}
            expandable
            expandableComponent={PaymentsListCell}
            fileName="comisiones-pagadas"
          />
        </StyledTable>
      )}
      {isLoadingCommissions && (
        <Container align="center">
          <Spinner type="dot" />
          <StyledDescription>Cargando lista de comisiones.</StyledDescription>
        </Container>
      )}
    </StyledPaidCommissionsTable>
  )
}

export default PaidCommissionsTable
