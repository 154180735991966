import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  StyledCommissionsTable,
  StyledCommissionsFooter,
  StyledTable,
  StyledTotalForCommission,
  StyledActionsContainer,
} from './styled'
import { ReactBaseElement } from '../../types/react'
import { getRoleResourceColumns, getTableData } from '../../utils/tables'
import Table from '../../components/organisms/Table'
import { useUser } from '../../providers/User'
import { useMutation, useQuery } from 'react-query'
import Spinner from '../../components/atoms/Spinner'
import Container from '../../components/atoms/Container'
import { StyledDescription } from '../../components/styled/Description'
import Input from '../../components/atoms/Input'
import { Icons, Role } from '../../types/enums'
import { TableSelectedData } from '../../types/tables'
import { formatCurrency } from '../../utils/currency'
import PaymentsListCell from '../../components/organisms/Table/Cells/PaymentsList'
import { CommissionResponse, CommissionTableRow } from '../../types/commissions'
import {
  calculateCommissions,
  getCommissions,
  payCommissions,
  updateCommission,
} from '../../services/commissions'
import {
  getConditionalStyles,
  parseCommissionForTable,
} from '../../utils/commissions'
import Button from '../../components/molecules/Button'
import { Mappeable } from '../../types/objects'
import { useTranslation } from '../../providers/I18n'
import Select from '../../components/atoms/Select'
import { SelectOption } from '../../types/html'
import { useModal } from 'react-modal-hook'
import EditCommissionsPercentage from '../../modals/EditCommissionsPercentage'

type CommissionsTableProps = ReactBaseElement & {
  type: 'advisor' | 'operator' | 'all'
}

const CommissionsTable: React.FC<CommissionsTableProps> = (props) => {
  const { user } = useUser()
  const { getTranslation } = useTranslation()
  const [search, setSearch] = useState('')
  const [localFilters, setLocalFilters] = useState<Mappeable<SelectOption>>({})
  const [stateCommissions, setStateCommissions] = useState<
    CommissionResponse[] | undefined
  >(undefined)
  const [selectedCommissions, setSelectedCommissions] =
    useState<TableSelectedData>()
  const navigator = useNavigate()

  const {
    data: commissions,
    isFetching,
    refetch,
  } = useQuery<CommissionResponse[]>(
    ['getCommissions'],
    () => getCommissions({ needsPayment: true }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setStateCommissions(data)
      },
    }
  )

  const { mutateAsync: payCommissionsHandler, isLoading: isPayingCommissions } =
    useMutation(payCommissions)
  const {
    mutateAsync: calculateCommissionsHandler,
    isLoading: isCalculatingCommissions,
  } = useMutation(calculateCommissions)

  const [showEditPercentageModal, hideEditPercentageModal] = useModal(
    ({ in: open }) => (
      <EditCommissionsPercentage
        open={open}
        onSubmit={async (newPercentage) => {
          if (newPercentage) {
            const updatedCommissions = selectedCommissions?.selectedRows.map(
              (com) => {
                return updateCommission(com.id, parseFloat(newPercentage) / 100)
              }
            )

            if (updatedCommissions) {
              await Promise.all(updatedCommissions)
              setSelectedCommissions({
                allSelected: false,
                selectedRows: [],
                selectedCount: 0,
              })
              refetch()
            }
          }
        }}
        onClose={() => {
          hideEditPercentageModal()
        }}
      />
    ),
    [selectedCommissions]
  )

  useEffect(() => {
    if (commissions) {
      const filteredCommissions = commissions!.filter(
        (comission: CommissionResponse) => {
          const celulaFilter = localFilters.celula?.value
          if (!celulaFilter || celulaFilter === '--') {
            return true
          }
          if (comission.celula) {
            return comission.celula._id.toString() === celulaFilter
          } else if (comission.owner) {
            return comission.owner._id.toString() === celulaFilter
          }
        }
      )

      const searchCommissions = filteredCommissions!.filter(
        (commission: CommissionResponse) => {
          return (
            commission.credit.code.toString().includes(search) ||
            commission.credit.client.name.toLowerCase().includes(search) ||
            commission.celula.name.toLowerCase().includes(search) ||
            commission.credit.bank.name.toLowerCase().includes(search) ||
            commission.credit.client.meta.contactForm.name
              .toLowerCase()
              .includes(search) ||
            commission.owner.displayName.toLowerCase().includes(search)
          )
        }
      )

      setStateCommissions(searchCommissions)
    }
  }, [localFilters, search, commissions])

  const filters = useMemo(() => {
    const celulas: Mappeable<string> = {}
    commissions?.forEach((commission: CommissionResponse) => {
      if (commission.celula) {
        celulas[commission.celula._id.toString()] = commission.celula.name
      } else if (commission.owner) {
        celulas[commission.owner._id.toString()] = commission.owner.displayName
      }
    })

    const celulasList = Object.keys(celulas).map((celulaKey) => ({
      value: celulaKey,
      label: celulas[celulaKey],
    }))

    const sortedCelulas = celulasList.sort((a, b) => {
      return a.label > b.label ? 0 : -1
    })

    return {
      celulas: sortedCelulas,
    }
  }, [commissions])

  const parsedCredits = useMemo(() => {
    if (stateCommissions && stateCommissions.length) {
      return parseCommissionForTable(
        stateCommissions,
        { getTranslation },
        false
      )
    }
    return []
  }, [stateCommissions])

  const totalSelected = useMemo(() => {
    let sum = 0
    if (selectedCommissions && selectedCommissions?.selectedCount > 0) {
      console.log(selectedCommissions?.selectedRows)
      sum = selectedCommissions?.selectedRows.reduce((total, current) => {
        return (
          total +
          (current.commissionAmountNumber ? current.commissionAmountNumber : 0)
        )
      }, 0)
    }
    return sum
  }, [selectedCommissions])

  if (isFetching || !stateCommissions) {
    return (
      <Container align="center">
        <Spinner type="dot" />
        <StyledDescription>Cargando lista de comisiones.</StyledDescription>
      </Container>
    )
  }

  const CommissionsColumns = getRoleResourceColumns('commissions', user?.role)
  const CommissionsData = getTableData(CommissionsColumns, parsedCredits)

  const pendingCommissions: Mappeable<string>[] = []
  const mapCommissionTypes = {
    advisor: /Asesora|Fija/,
    operator: /Op. Etapa/,
    all: /(.*)/,
  }
  const commissionRegexp = mapCommissionTypes[props.type]
  CommissionsData.forEach((commission) => {
    if (commission.privateNeedsPayment) {
      if (commission.commissionType.match(commissionRegexp)) {
        pendingCommissions.push(commission)
      }
    }
  })

  const handleClick = (commission: CommissionTableRow) => {
    navigator(`/credito/${commission.creditCode}`)
  }

  const handleLocalFilter = (filter: string) => (option: SelectOption) => {
    setLocalFilters({
      ...localFilters,
      [filter]: option,
    })
  }

  const handlePayCommissions = () => {
    const ids = selectedCommissions?.selectedRows.map(
      (commission: CommissionTableRow) => commission.id
    )
    payCommissionsHandler(ids || []).then(() => {
      setSelectedCommissions({
        allSelected: false,
        selectedRows: [],
        selectedCount: 0,
      })
      refetch()
    })
  }

  const handleCalculate = () => {
    calculateCommissionsHandler().then(() => {
      refetch()
    })
  }

  const handleSelect = (selected: TableSelectedData) => {
    setSelectedCommissions(selected)
  }

  const handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const searchValue = event.currentTarget.value.toLowerCase()
    setSearch(searchValue)
  }

  const conditional = getConditionalStyles()

  const showCommissionsActions = [Role.Admin, Role.Director].includes(
    user?.role as Role
  )

  const showCommissionTotalToUser = [Role.Advisor, Role.Operator].includes(
    user?.role as Role
  )

  return (
    <StyledCommissionsTable>
      {showCommissionsActions && (
        <Container align="right">
          <Button onClick={handleCalculate} loading={isCalculatingCommissions}>
            Calcular Comisiones
          </Button>
        </Container>
      )}
      <StyledTable>
        <Container direction="row">
          {showCommissionsActions && (
            <Select
              label="Celula"
              items={filters.celulas}
              onChange={handleLocalFilter('celula')}
              value={localFilters.celula}
            />
          )}
          <Input
            placeholder="Buscar"
            onChange={handleSearch}
            preIcon={Icons.SEARCH}
            value={search}
          ></Input>
        </Container>
        {showCommissionTotalToUser && (
          <StyledTotalForCommission>
            Total de Comisiones Seleccionadas : {formatCurrency(totalSelected)}
          </StyledTotalForCommission>
        )}
        <Table
          columns={CommissionsColumns}
          data={pendingCommissions}
          onClick={handleClick}
          onSelect={handleSelect}
          conditionalRowStyles={conditional}
          expandable
          expandableComponent={PaymentsListCell}
          fileName="comisiones-pendientes"
        />
        {showCommissionsActions &&
          selectedCommissions &&
          selectedCommissions?.selectedCount > 0 && (
            <StyledCommissionsFooter>
              <StyledDescription>
                Tienes seleccionadas{' '}
                <span>{selectedCommissions?.selectedCount}</span> comisiones.
                Deseas marcar estas comisiones como pagadas?
              </StyledDescription>
              <StyledTotalForCommission>
                Total de selección a comisionar: {formatCurrency(totalSelected)}
              </StyledTotalForCommission>
              <StyledActionsContainer>
                <Button
                  onClick={handlePayCommissions}
                  loading={isPayingCommissions}
                >
                  Marcar como Pagadas
                </Button>
                <Button onClick={showEditPercentageModal}>
                  Modificar Porcentaje
                </Button>
              </StyledActionsContainer>
            </StyledCommissionsFooter>
          )}
      </StyledTable>
    </StyledCommissionsTable>
  )
}

export default CommissionsTable
